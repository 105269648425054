<template>
  <v-main>
    <v-fade-transition mode="out-in">
      <router-view 
        :admin="admin"
      />
    </v-fade-transition>
  </v-main>
</template>

<script>
  export default {
    name: 'PageView',
    props: {
      admin: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>
